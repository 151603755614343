// TODO: make mark-specific default
export const CHANNEL_DEFAULTS = {
    // https://mikemol.github.io/technique/colorblind/2018/02/11/color-safe-palette.html
    NOMINAL_COLOR: ['#E79F00', '#029F73', '#0072B2', '#CB7AA7', '#D45E00', '#57B4E9', '#EFE441' /*'#000000'*/],
    // https://colorcet.holoviz.org/user_guide/Categorical.html
    NOMINAL_COLOR_EXTENDED: [
        '#d60000',
        '#018700',
        '#b500ff',
        '#05acc6',
        '#97ff00',
        '#ffa52f',
        '#ff8ec8',
        '#79525e',
        '#00fdcf',
        '#afa5ff',
        '#93ac83',
        '#9a6900',
        '#366962',
        '#d3008c',
        '#fdf490',
        '#c86e66',
        '#9ee2ff',
        '#00c846',
        '#a877ac',
        '#b8ba01',
        '#f4bfb1',
        '#ff28fd',
        '#f2cdff',
        '#009e7c',
        '#ff6200',
        '#56642a',
        '#953f1f',
        '#90318e',
        '#ff3464',
        '#a0e491',
        '#8c9ab1',
        '#829026',
        '#ae083f',
        '#77c6ba',
        '#bc9157',
        '#e48eff',
        '#72b8ff',
        '#c6a5c1',
        '#ff9070',
        '#d3c37c',
        '#bceddb',
        '#6b8567',
        '#916e56',
        '#f9ff00',
        '#bac1df',
        '#ac567c',
        '#ffcd03',
        '#ff49b1',
        '#c15603',
        '#5d8c90',
        '#c144bc',
        '#00753f',
        '#ba6efd',
        '#00d493',
        '#00ff75',
        '#49a150',
        '#cc9790',
        '#00ebed',
        '#db7e01',
        '#f77589',
        '#b89500',
        '#c84248',
        '#00cff9',
        '#755726',
        '#85d401',
        '#ebffd4',
        '#a77b87',
        '#db72c8',
        '#cae256',
        '#8abf5d',
        '#a1216b',
        '#855b89',
        '#89bacf',
        '#ffbad6',
        '#b6cfaa',
        '#97414d',
        '#67aa00',
        '#fde1b1',
        '#ff3628',
        '#80793d',
        '#d6e8ff',
        '#a795c6',
        '#7ea59a',
        '#d182a3',
        '#54823b',
        '#e6a872',
        '#9cffff',
        '#da5480',
        '#05b3aa',
        '#ffaaf6',
        '#d1afef',
        '#da015d',
        '#ac1a13',
        '#60b385',
        '#d442fd',
        '#acaa59',
        '#fb9ca7',
        '#b3723b',
        '#f26952',
        '#aed1d4',
        '#9affc3',
        '#dbb333',
        '#eb01c3',
        '#9900c4',
        '#cfff9e',
        '#a55949',
        '#3b6d01',
        '#008579',
        '#959167',
        '#89dbb3',
        '#6d7400',
        '#aa5dca',
        '#07ef00',
        '#804f3d',
        '#d88052',
        '#ffc862',
        '#b8009e',
        '#99acdd',
        '#904f00',
        '#8c4470',
        '#4f6e52',
        '#ff8734',
        '#c68ecd',
        '#d4e29e',
        '#b1826d',
        '#9cfb75',
        '#56dd77',
        '#f90087',
        '#a1cdff',
        '#13cad1',
        '#118e54',
        '#d154a5',
        '#00dfc3',
        '#a3832f',
        '#77975b',
        '#baaa80',
        '#70a3af',
        '#d6fbff',
        '#e8013a',
        '#d84621',
        '#ff82ed',
        '#b63862',
        '#b6cd72',
        '#97626b',
        '#897490',
        '#00a316',
        '#00f4a1',
        '#bf90f2',
        '#89e4d8',
        '#a34d95',
        '#6e5d00',
        '#8cc68e',
        '#95aa2a',
        '#c672dd',
        '#b33b01',
        '#d69a36',
        '#dfacb6',
        '#009aa0',
        '#599000',
        '#97bca8',
        '#ac8ca8',
        '#dad4ff',
        '#547c72',
        '#00ba69',
        '#ffc38e',
        '#b800d4',
        '#dfcf5b',
        '#629a7b',
        '#bfedbc',
        '#c1bdfd',
        '#80d3dd',
        '#e2857e',
        '#f9eb4d',
        '#bf6d82',
        '#caff4f',
        '#ef72aa',
        '#ed67ff',
        '#9946ae',
        '#6d6942',
        '#e25660',
        '#dd662d',
        '#9cdb5d',
        '#e29ccf',
        '#b87500',
        '#c6002d',
        '#dfbcda',
        '#59b5df',
        '#ff59da',
        '#38c1a1',
        '#9e698c',
        '#acaac8',
        '#95622f',
        '#b55662',
        '#2b7e60',
        '#b1e400',
        '#eda590',
        '#95fde2',
        '#ff548e',
        '#bd6ea1',
        '#aa3b36',
        '#d8cf00',
        '#aa80cd',
        '#a08052',
        '#e100e8',
        '#c35b3d',
        '#b53a85',
        '#8c7700',
        '#dbbc95',
        '#529e93',
        '#afbc82',
        '#91b5b6',
        '#a75423',
        '#ffd4ef',
        '#79ae6b',
        '#5db54b',
        '#80fb9a',
        '#48ffef',
        '#979548',
        '#9387a7',
        '#31d400',
        '#6ee956',
        '#b6d4eb',
        '#705470',
        '#f2db8a',
        '#aad4c1',
        '#7ecdf2',
        '#89ba00',
        '#64b6ba',
        '#ffb500',
        '#c38285',
        '#caaa5e',
        '#647748',
        '#59e2ff',
        '#df4dcd',
        '#e9ff79',
        '#bc66b8',
        '#c395a5',
        '#64c674',
        '#d19570',
        '#70cf4f',
        '#aa6e66',
        '#9c60a5',
        '#00b800',
        '#e299b3',
        '#bc006b',
        '#b3e8ef',
        '#cdbfe4',
        '#77a342',
        '#856277',
        '#568e5b',
        '#9eafc4',
        '#e82fa0',
        '#247c2a',
        '#826723',
        '#bfbc4d',
        '#ddd3a5'
    ],
    QUANTITATIVE_COLOR: 'viridis',
    SIZE: 3,
    SIZE_RANGE: [2, 6]
};
